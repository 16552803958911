import React from 'react'; // This is the default export
import ReactDOM from 'react-dom/client'; // This is the default export
import { Footer } from './components';

import App from './App'; // Import app.js

const root = ReactDOM.createRoot(document.getElementById('root'));  // Create a root element
const footer = ReactDOM.createRoot(document.getElementById('footer'));  // Create a root element
root.render( // Render the root element
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
)


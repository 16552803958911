

import React from 'react';
import './gallery.css';

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../pages/gallery/pictures', false, /\.(png|jpe?g|svg)$/));

const Gallery = () => (
  <div className="gallery">
    {images.map((image, index) => (
      <figure key={index} className={`gallery__item gallery__item--${index + 1}`}>
        <img src={image} className="gallery__img" alt={`DisplayImage ${index + 1}`} />
        <p>Image {index + 1}</p>
      </figure>
    ))}
  </div>
);


export default Gallery;
import { BrowserRouter, Route, Routes} from "react-router-dom";
import { Home, Gallery } from './pages/index'; // Import the Home page
import { Navbar } from './components'; // Import the components
import NotFound from './components/handlers/404'; // Corrected import for NotFound (no curly braces)

import './styles/index'; // Import the styles

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        
        {/* Main Home Page */}
        <Route path="/" element={<Home />} />

        {/* Main Home Page */}
        <Route path="/gallery" element={<Gallery />} />

        {/* Catch-all route for 404 - anything else */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Link } from "react-router-dom"; 
import {  Link as SmoothScroll } from "react-scroll"; 

import './navbar.css'; 

import { images } from '../../images/index';


const Navbar = () => { 
  //const location = useLocation(); 

  return ( 
    <header>
      <div className="navbar_left">
        <img className="img-round" src={images.profilePicute} alt="pfp" id="pfp" width="70px"></img>
      </div>
      <div id="border">
        <nav>
          <ul className="nav__links">
            <li><Link to="">Home</Link></li>
            <li><SmoothScroll to="about-me" smooth={"true"}>About</SmoothScroll></li>
            <li><Link to="gallery" smooth={"true"}>Gallery</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}


            // {location.pathname === '/' && <li><SmoothScroll to="about-me" smooth={"true"}>About</SmoothScroll></li>} 
            //            <li><Link to="qoutes"></Link>Qoutes</li>
            //<li><Link to="music"></Link>Music</li>
            //<li><Link to="shows"></Link>Shows</li>
export default Navbar; // Export the Navbar component
